.site-wrapper {
    width: 1170px;
    margin-left: auto;
    margin-right: auto;
}

html {
    /* this makes sure the padding and the border is included in the box sizing */
    box-sizing: border-box;
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: $main;
}

::-webkit-scrollbar-thumb {
    background: $red;
    border-radius: 5px;
}

.draw-appbar {
    position: relative !important;
    background: $dark !important;
}

main.content {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 875px;
}

.hero {
    height: 620px;
    box-shadow: $shadow;
    margin-top: 0;
    .cta {
        color: #fff;
        max-width: 600px;
        z-index: 1;
        h1 {
            color: #fff;
            font-size: 60px;
            line-height: 0.7;
            .dot {
                &:after {
                    content: "";
                    background-color: $red;
                    border-radius: 50%;
                    display: inline-block;
                    margin-left: 5px;
                    height: 13px;
                    width: 13px;
                }
            }
        }
        p {
            font-size: 16px;
            line-height: 1.8;
        }
    }
}

.experience-year {
    font-size: 12px;
}

.experience-title {
    display: flex;
    justify-content: space-between;
}

section {
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    margin-top: 50px;
    &.dark-bg shadow {
        background: #fff;
    }
    &.padding {
        padding: 80px 50px;
    }
    &.padding-50 {
        padding: 50px;
    }
    &.padding-60 {
        padding: 60px 50px;
    }
    &.dark-bg {
        background: $dark;
    }
    &.shadow {
        box-shadow: $shadow;
    }
}

.section-title {
    margin: 0;
    color: #efefef;
    &:after {
        content: "";
        background: $red;
        border-radius: 25px;
        display: block;
        height: 5px;
        margin-top: 14px;
        width: 30px;
    }
}

.tech-title,
.techstack {
    font-weight: 500;
}

.techstack {
    font-size: 14px;
}

.list-bg {
    background: $dark;
}

.organization,
.org-location {
    color: $red;
    font-weight: 500;
}

.organization {
    font-size: 24px;
}

.text-white {
    color: #efefef;
}

.header-avatar {
    width: 70px;
    border-radius: 50%;
}

.border-dark {
    border-color: $main;
}

.drawer-hire-btn {
    background-color: $red !important;
}

/*=================================================================*/
/*                      SKILLS                              
/*=================================================================*/
.skill-item .skill-info span {
    font-size: 14px;
}

.skill-item {
    h4 {
        font-size: 14px;
        text-transform: uppercase;
    }
}

.fact-item {
    h2 {
        font-size: 36px;
        margin: 12px 0 7px;
    }
    span {
        font-size: 14px;
    }
    h2 span {
        font-size: 36px;
    }
}

.service-item {
    border: solid 1px #efefef;
    border-radius: 10px;
    padding: 30px 20px;
    @include transition(0.3s);
    h4,
    i {
        @include transition(0.3s);
    }
    &:hover {
        border-color: $red;
        background: $red;
        color: #fff;
        -webkit-box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.15);
        h4,
        i {
            color: #fff;
        }
    }
}

.timeline {
    position: relative;
    padding: 70px 0;
}

.badge-red {
    background-color: $red !important;
}

.text-red {
    color: $red;
}

.drawer-body {
    background: $dark;
}

.timeline-line {
    position: absolute;
    top: 0px;
    left: calc(20% + 2px);
    bottom: 0px;
    width: 1px;
    background: $red;
    &::before,
    &::after {
        content: "";
        display: block;
        border-radius: 100%;
        background: $red;
        position: absolute;
        left: -3px;
        height: 7px;
        width: 7px;
    }
    &::before {
        top: 0;
    }
    &::after {
        bottom: 0;
    }
}

.timeline:after {
    content: "";
    display: table;
    clear: both;
}

.entry {
    clear: both;
    position: relative;
    &:last-of-type {
        .body {
            margin-bottom: 0;
        }
    }
}

.entry .title {
    float: left;
    width: 20%;
    position: relative;
}

.entry .title:before {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    border: 1px solid $red;
    background-color: #fff;
    border-radius: 100%;
    padding: 4.5px;
    top: 15%;
    right: -8px;
    z-index: 1;
}

.entry .body {
    margin: 0 0 6em;
    float: right;
    width: 80%;
    padding-left: 60px;
    p {
        margin-bottom: 0;
    }
}

/*=================================================================*/
/*                      PORTFOLIO                              
/*=================================================================*/
.portfolio-wrapper {
    [class*="col-"] {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.pf-filter-wrapper {
    display: none;
}

.portfolio-item {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    cursor: pointer;
    @include transition(0.3s);
    .thumb {
        overflow: hidden;
        img {
            -webkit-transform: scale(1);
            -moz-transform: scale(1);
            -ms-transform: scale(1);
            -o-transform: scale(1);
            transform: scale(1);
            @include transition(0.3s);
        }
    }
    .details {
        color: #fff;
        position: absolute;
        padding: 0 25px;
        top: 25px;
        width: 100%;
        z-index: 1;
        h4 {
            color: #fff;
            margin: 0 0 4px;
            opacity: 0;
            transform: translateY(30px);
            transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
        }
        span {
            font-size: 12px;
            opacity: 0;
            display: block;
            transform: translateY(40px);
            transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
        }
    }
    .plus-icon {
        color: $red;
        background: #fff;
        border-radius: 100%;
        position: absolute;
        font-size: 16px;
        left: 25px;
        bottom: 25px;
        opacity: 0;
        line-height: 32px;
        text-align: center;
        height: 30px;
        width: 30px;
        z-index: 1;
        @include transition(0.3s);
    }
    .mask {
        background: $red;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        height: 100%;
        width: 100%;
        @include transition(0.3s);
    }
    &:hover {
        -webkit-box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.15);
        .mask {
            background: $red;
            opacity: 0.8;
        }
        img {
            -webkit-transform: scale(1);
            -moz-transform: scale(1);
            -ms-transform: scale(1);
            -o-transform: scale(1);
            transform: scale(1.1);
        }
        .details {
            h4,
            span {
                opacity: 1;
                transform: translateY(0);
            }
        }
        .plus-icon {
            opacity: 1;
        }
    }
}

.load-more {
    i {
        display: none;
    }
}

.portfolio-info {
    color: #efefef;
    font-size: 13px;
}

.portfolio-info li:not(:last-child) {
    margin-right: 1.5rem;
}

.portfolio-info i {
    color: $red;
    font-size: 14px;
    margin-right: 10px;
}

.portfolio-filter {
    margin-bottom: 25px;
    li {
        color: #efefef;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        position: relative;
        &:hover {
            color: #000;
            &:after {
                height: 7px;
            }
        }
        &.current {
            background: $red;
            color: #fff;
            padding: 6px 14px 4px;
            border-radius: 30px;
        }
    }
    li:not(:last-child) {
        margin-right: 1.5rem;
    }
}

.price-item {
    border: solid 1px #efefef;
    border-radius: 10px;
    padding: 30px 0;
    i {
        background: #fff;
        font-size: 24px;
        color: $red;
        display: block;
        border-radius: 50%;
        border: solid 1px #efefef;
        height: 55px;
        line-height: 55px;
        left: 50%;
        top: -27.5px;
        position: absolute;
        text-align: center;
        width: 55px;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
    }
    .price-icon + .plan {
        margin-top: 25px;
    }
    .plan {
        font-size: 12px;
        color: #fff;
        background: $red;
        border-radius: 25px;
        display: inline-block;
        padding: 1px 9px;
    }
    .price {
        font-size: 36px;
        margin: 25px 0;
        em {
            font-size: 18px;
            font-style: normal;
            vertical-align: super;
            margin-right: 4px;
        }
        span {
            color: #efefef;
            font-size: 12px;
            font-weight: 400;
        }
    }
    ul {
        list-style: none;
        padding-left: 0;
        margin: -8px 0;
        li {
            line-height: 30px;
        }
    }
    .btn {
        margin-top: 30px;
    }
    &.recommended {
        color: #fff;
        background: $colorblue;
        .price {
            color: #fff;
            span {
                color: #fff;
            }
        }
    }
}

.testimonial-item {
    border: solid 1px #efefef;
    border-radius: 10px;
    padding: 30px 25px;
    .symbol {
        font-weight: bold;
        color: $red;
        font-size: 12px;
        margin-bottom: 20px;
        display: block;
    }
    p {
        margin-bottom: 0;
    }
    .testimonial-details {
        display: flex;
        margin-top: 25px;
        .thumb {
            display: inline-block;
            border-radius: 100%;
            overflow: hidden;
            height: 40px;
            vertical-align: middle;
            width: 40px;
        }
        .info {
            display: inline-block;
            margin-left: 20px;
            vertical-align: middle;
            h4 {
                margin: 0;
            }
            span {
                font-size: 11px;
                text-transform: uppercase;
                letter-spacing: 0.4px;
            }
        }
    }
}

/*=================================================================*/
/*                      CLIENTS                              
/*=================================================================*/
.client-item {
    min-height: 60px;
    position: relative;
    .inner {
        text-align: center;
        position: absolute;
        left: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 100%;
    }
    img {
        display: inline-block;
    }
}

/*=================================================================*/
/*                      BLOG                              
/*=================================================================*/
.blog-item {
    .thumb {
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        @include transition(0.3s);
        img {
            -webkit-transform: scale(1);
            -ms-transform: scale(1);
            transform: scale(1);
            @include transition(0.3s);
        }
        &:hover {
            -webkit-box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.15);
            -moz-box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.15);
            box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.15);
            img {
                -webkit-transform: scale(1.1);
                -ms-transform: scale(1.1);
                transform: scale(1.1);
            }
        }
    }
    .category {
        border-radius: 50px;
        background: $red;
        color: #fff;
        font-size: 12px;
        padding: 1px 8px;
        position: absolute;
        left: 20px;
        top: 20px;
        z-index: 1;
    }
    .meta {
        color: #adadad;
        font-size: 12px;
        li:not(:last-child) {
            margin-right: 1rem;
        }
        li {
            &:after {
                content: "";
                background: $red;
                border-radius: 100%;
                display: inline-block;
                height: 3px;
                margin-left: 1rem;
                vertical-align: middle;
                width: 3px;
            }
            &:last-child::after {
                display: none;
            }
        }
    }
}

.contact-info {
    i {
        font-size: 24px;
        color: $red;
        float: left;
    }
    .details {
        margin-left: 40px;
        h5 {
            font-size: 16px;
            margin: 0 0 5px;
        }
    }
}

/*=================================================================*/
/*                      HELPER                              
/*=================================================================*/
.overlay {
    border-radius: 20px;
    background: $dark;
    left: 0;
    top: 0;
    opacity: 0.9;
    height: 100%;
    position: absolute;
    width: 100%;
}

.icon-circle {
    font-size: 24px;
    color: #fff;
    display: inline-block;
    background: $red;
    border-radius: 50%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    width: 50px;
}

.icon-simple {
    color: $red;
    font-size: 24px;
}

#infscr-loading {
    position: absolute;
    left: 50%;
    margin-left: -15px;
    bottom: 10px;
}

#infscr-loading img {
    display: none;
}

.shadow-blue {
    -webkit-box-shadow: 0px 6px 15px 0px rgba(74, 99, 231, 0.1);
    -moz-box-shadow: 0px 6px 15px 0px rgba(74, 99, 231, 0.1);
    box-shadow: 0px 6px 15px 0px rgba(74, 99, 231, 0.1);
}

.shadow-dark {
    -webkit-box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.2);
}

.parallax {
    background-size: cover;
    background-attachment: fixed;
}
